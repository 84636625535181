<template>
    <div class="club-home-page">

        <div class="com-home-top-nav">
            <div class="content">
                <ul class="club-list clearfix">

                    <li v-for="forum in forums" :key="forum.id">
                        <router-link class="clearfix" :to="{name: 'bbs-forum', query: {id: forum.id}}">
                            <div class="img-w item-left">
                                <img :src="forum.icon" />
                            </div>
                            <div class="item-right">
                                <div class="div1">
                                    <span class="name">{{forum.name}}</span>
                                    <span class="count">话题：{{forum.threads}}</span>
                                </div>
                                <div class="des">{{forum.description}}</div>
                            </div>
                        </router-link>
                    </li>

                </ul>
            </div>
        </div>

        <thread-list type="all" :page="page" />

    </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, Ref } from 'vue';
import {mapState} from 'vuex';

import {useForumList} from '@/compositions/bbs';
import ThreadList from '@/components/bbs/thread-list.vue';

export default defineComponent({

    components: {
        ThreadList
    },

    props: {
        page: Number
    },

    setup: (props) => {
        return useForumList();
    }

});
</script>

<style>
.header-background-gray {
    color: #f5f5f5
}

.block-item>.more-btn,
.load-more-btn {
    display: block;
    height: .8rem;
    line-height: .8rem;
    font-size: 18px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #f5f5f5;
    text-align: center;
    color: #666
}

.t-a-c {
    text-align: center
}

.container .club-home-page {
    width: 1200px;
    margin: 0 auto
}

.container .com-home-top-nav {
    margin: 20px auto
}

.com-home-top-nav>.content>.club-list {
    margin: 0 -20px
}

.com-home-top-nav>.content>.club-list>li {
    float: left;
    width: 25%;
    padding: 10px 20px
}

.com-home-top-nav>.content>.club-list>li>a {
    display: block
}

.com-home-top-nav>.content>.club-list>li>a>.item-left {
    float: left
}

.com-home-top-nav>.content>.club-list>li>a>.item-left.img-w {
    width: 60px;
    height: 60px;
    overflow: hidden
}

.com-home-top-nav>.content>.club-list>li>a>.item-left.img-w img {
    width: 50px;
    height: auto;
    margin: 5px;
    transition: all .3s
}

.com-home-top-nav>.content>.club-list>li>a>.item-right {
    margin-left: 70px;
    line-height: 25px;
    padding-top: 5px
}

.com-home-top-nav>.content>.club-list>li>a>.item-right .name {
    font-size: 16px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-home-top-nav>.content>.club-list>li>a>.item-right .count,
.com-home-top-nav>.content>.club-list>li>a>.item-right .des {
    font-size: 12px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-home-top-nav>.content>.club-list>li>a>.item-right .count {
    padding-left: 20px;
    color: #999
}

.com-home-top-nav>.content>.club-list>li>a>.item-right .des {
    color: #666;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.com-home-top-nav>.content>.club-list>li>a:hover img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
}

.com-channel-list>ul>li>.item-right .content-bottom .huifu {
    min-width: 16px;
    height: 25px;
    background: url("../../assets/files/1acce479cbd240dca750fba1c47045c3.png") left center no-repeat;
    background-size: 19px 16px;
    padding-left: 20px
}

.com-channel-list>ul>li>.item-right .content-bottom .zan {
    margin-right: 20px;
    background: url("../../assets/files/bf8c12d1464586dd9df83d60cfdf0ced.png") left center no-repeat;
    background-size: auto 16px;
    padding-left: 20px
}

.com-side-dianping-list>ul {
    overflow: hidden
}

.com-side-dianping-list>ul>li {
    padding: 20px 0;
    border-bottom: 1px solid #e9e9e9
}

.com-side-dianping-list>ul>li:last-child {
    border: none
}

.com-side-dianping-list>ul>li>.row1 {
    line-height: 30px
}

.com-side-dianping-list>ul>li>.row1>span {
    display: inline-block;
    height: 30px;
    vertical-align: middle
}

.com-side-dianping-list>ul>li>.row1>span+span {
    margin-left: 15px
}

.com-side-dianping-list>ul>li>.row1 .img-w {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    overflow: hidden;
    display: inline-block
}

.com-side-dianping-list>ul>li>.row1 .img-w img {
    display: block;
    width: 30px;
    height: auto
}

.com-side-dianping-list>ul>li>.row1 .img-w mip-img {
    display: inline-block
}

.com-side-dianping-list>ul>li>.row1 .img-w mip-img img {
    min-width: inherit
}

.com-side-dianping-list>ul>li>.row1 .name {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.com-side-dianping-list>ul>li>.row1 .date {
    font-size: 12px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #999
}

.com-side-dianping-list>ul>li>.row2 {
    margin: 8px 0;
    line-height: 24px
}

.com-side-dianping-list>ul>li>.row2>.star-w {
    width: 90px;
    height: 24px;
    background: url("../../assets/files/2366a990ca4a3e16f76a00a481670657.png") left center no-repeat
}

.com-side-dianping-list>ul>li>.row2>.star-w>.score {
    width: 0;
    height: 100%;
    background: url("../../assets/files/6af5ad5cfd07d0ba5aa9ebb7217445af.png") left center no-repeat
}

.com-side-dianping-list>ul>li>.row3 {
    line-height: 25px
}

.channel-topic-list>ul {
    border: 1px solid #e9e9e9;
    border-top: none;
    border-bottom: none;
    font-size: 16px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.channel-topic-list>ul>li {
    min-height: 60px;
    line-height: 58px;
    padding: 0 0 0 20px;
    border-bottom: 1px solid #e9e9e9;
    overflow: hidden
}

.channel-topic-list>ul>li.first {
    height: 42px;
    min-height: 42px;
    line-height: 40px;
    border-top: 1px solid #e9e9e9;
    background-color: #FAFAFA
}

.channel-topic-list>ul>li.first:hover {
    background-color: #FAFAFA
}

.channel-topic-list>ul>li>.col {
    float: left;
    height: 100%;
    padding: 0 10px
}

.channel-topic-list>ul>li>.col>.qz {
    position: absolute;
    top: 30px;
    left: 0;
    width: 18px;
    height: 18px;
    margin-top: -9px
}

.channel-topic-list>ul>li>.col>.title-text {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    padding-right: 60px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.channel-topic-list>ul>li>.col>.athor-name,
.channel-topic-list>ul>li>.col>.publish-time {
    display: block;
    line-height: 1.5;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.channel-topic-list>ul>li>.col>.athor-name {
    margin-top: 13px;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.channel-topic-list>ul>li>.col>.publish-time {
    font-size: 12px;
    color: #999
}

.channel-topic-list>ul>li>.col1 {
    width: 65%;
    position: relative;
    padding-left: 30px
}

.channel-topic-list>ul>li>.col2 {
    width: 15%
}

.channel-topic-list>ul>li>.col3,
.channel-topic-list>ul>li>.col4 {
    width: 10%;
    font-size: 14px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.channel-topic-list>ul>li .tu {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-left: 3px;
    margin-top: 1px;
    background: url("../../assets/files/1fb78ec41550b63eb950a3b273c7dd57.png") center no-repeat;
    vertical-align: text-top
}

.channel-topic-list>ul>li .ding:after,
.channel-topic-list>ul>li .jing:after,
.channel-topic-list>ul>li .qianzhui,
.channel-topic-list>ul>li .wen:after {
    display: block;
    width: 18px;
    height: 18px;
    line-height: 16px;
    font-size: 12px;
    text-align: center;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.channel-topic-list>ul>li .qianzhui {
    border-radius: 2px
}

.channel-topic-list>ul>li .ding:after {
    border-radius: 2px;
    border: 1px solid #EE4653;
    color: #EE4653;
    content: '顶'
}

.channel-topic-list>ul>li .wen:after {
    border-radius: 2px;
    border: 1px solid #7eb4ed;
    color: #7eb4ed;
    content: '问'
}

.channel-topic-list>ul>li .jing:after {
    border-radius: 2px;
    border: 1px solid #f90;
    color: #f90;
    content: '精'
}

.channel-topic-list>ul>li .img-container {
    margin-bottom: 22px
}

.channel-topic-list>ul>li .img-container img {
    display: inline-block;
    vertical-align: middle;
    height: 100px;
    width: auto
}

.channel-topic-list>ul>li .img-container img+img {
    margin-left: 20px
}

.channel-topic-list>ul>li .img-container a {
    display: block
}

.channel-topic-list>ul>li:hover {
    background-color: #f5f5f5
}

.channel-topic-list>.empty-box {
    min-height: 250px;
    margin-top: 60px;
    background: url("../../assets/files/2f7b2c2791cf9406fd24c3a25e605d92.png") center no-repeat
}

.channel-topic-list>.page-empty-data {
    border: 1px solid #e9e9e9;
    background: url("../../assets/files/2f7b2c2791cf9406fd24c3a25e605d92.png") center no-repeat #fff;
    height: 440px;
    position: relative
}

.channel-topic-list>.page-empty-data span {
    position: absolute;
    height: 340px;
    left: 0;
    width: 100%;
    bottom: 50px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    text-align: center;
    color: #999;
    padding-top: 270px
}

.channel-topic-list>.page-empty-data span:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background: url("../../assets/files/2f7b2c2791cf9406fd24c3a25e605d92.png") center 30px no-repeat;
    background-size: 280px
}

.channel-topic-list>.list-page-footer {
    padding-top: 20px
}

.channel-topic-list>.list-page-footer>.fl {
    padding: 30px 0
}

.channel-topic-list>.list-page-footer>.fl>span {
    display: inline-block;
    line-height: 60px;
    padding: 0 20px;
    background-color: #EE4653;
    border-radius: 5px;
    color: #fff;
    font-size: 18px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.common-paging>ul>li.pre {
    background: url("../../assets/files/405fee75f912878a3a64cb3185510e6f.png") center no-repeat
}

.common-paging>ul>li.next {
    background: url("../../assets/files/0d6cea75496cac7ee3a629e5369cb9d7.png") center no-repeat
}

.common-paging>ul>li.disabled.pre {
    background: url("../../assets/files/e564bffffcfb26636113cb226064784e.png") center no-repeat
}

.common-paging>ul>li.disabled.next {
    background: url("../../assets/files/6fcaa9f4e4f732405a58f524004e8e92.png") center no-repeat
}

.layout-user {
    height: 34px;
    line-height: 20px;
    padding: 7px 0;
    background: #f5f5f5;
    color: #999
}

.layout-user>.content .left-link>li .tiku-mune ul>li a.active,
.layout-user>.content .left-link>li .tiku-mune ul>li a:hover,
.layout-user>.content .right-link .active,
.layout-user>.content .right-link a:hover {
    color: #37B5F8
}

.layout-user>.content .city {
    padding-left: 15px;
    background: url("../../assets/files/1272afef1a66936bae7d785d24e4b319.png") left center no-repeat;
    cursor: pointer
}

.com-top-user-city .city {
    padding-left: 15px;
    background: url("../../assets/files/eae9b23cf7983349005bb5e7e771478a.png") left center no-repeat;
    cursor: pointer
}

.common-city-selection>.list>ul>li>.item>ul {
    padding-left: 58px
}

.common-city-selection-dialog>.close {
    width: 25px;
    height: 25px;
    background: url("../../assets/files/a7474b44821b980050ffead66d07dd38.png") center no-repeat;
    right: 0;
    top: 0;
    cursor: pointer;
    position: absolute;
    display: block
}

.layout-navigation>.navigation-top>.logo-w .logo {
    display: block;
    width: 207px;
    height: 100%;
    overflow: hidden;
    vertical-align: middle;
    text-align: left;
    background: url("../../assets/files/faab6749bd917811e688ac5d5bc2b782.png") left center no-repeat
}

.layout-navigation>.navigation-top .search-w button {
    position: absolute;
    top: 7px;
    right: 5px;
    height: 29px;
    width: 30px;
    background: url("../../assets/files/988d5f1c9d31781584e82d0ecad4f582.png") center no-repeat;
    border: none
}

.layout-navigation>.navigation-content>.nav-w>li {
    display: inline-block;
    padding: 0;
    vertical-align: top;
    width: 107px;
    text-align: center
}

.layout-navigation2>.logo-w .logo {
    display: block;
    width: 207px;
    height: 100%;
    overflow: hidden;
    vertical-align: middle;
    text-align: left;
    background: url("../../assets/files/5309392905c9eaaccdc170f078756f78.png") left center no-repeat
}

.layout-navigation2>.nav-w .search-w button {
    position: absolute;
    top: 2px;
    right: 5px;
    height: 29px;
    width: 30px;
    background: url("../../assets/files/71073a79ef0afc287a4030ad69dfcdce.png") center no-repeat;
    border: none
}

.layout-footer>.content .footer3 {
    height: 108px;
    width: 1200px;
    padding-top: 25px;
    margin: 0 auto;
    padding-bottom: 18px;
    overflow: hidden
}

.layout-footer>.content .footer3 .left {
    float: left;
    margin-left: 180px;
    padding-right: 70px;
    border-right: 1px solid #666;
    width: 232px;
    height: 108px
}

.layout-footer>.content .footer3 .left a {
    display: block;
    padding-top: 15px;
    height: 56px
}

.layout-footer>.content .footer3 .right {
    float: left;
    height: 108px;
    margin-left: 70px
}

.com-dialog-growUp .growUp {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 6rem;
    height: 6rem;
    border-radius: 8px;
    background: url("../../assets/files/fe1205c93682f15045fb62830fb73483.png") center .34rem no-repeat #fff;
    background-size: 3.72rem
}

.com-enroll-pannel .input-box>form>.row .submit-btn.loading {
    background: url("../../assets/files/2e15855e2d3012e2f442c66c952800a9.gif") center no-repeat;
    background-size: contain;
    cursor: default;
    color: transparent
}

.com-dialog-enroll-freephone .dialog-regist-box>.row-tip {
    margin-top: -5px;
    color: #999;
    text-align: center
}

.com-dialog-login .dialog-login-box>.tab-content-w .tab-content-login .other-login>.type .qq {
    width: 44px;
    height: 44px;
    background: url("../../assets/files/8f8a1b78796d67a65e2de8379c81b4ee.png") center center no-repeat;
    background-size: 44px 44px;
    display: block;
    margin: 0 auto
}

.com-dialog-login .dialog-login-box>.tab-content-w .tab-content-coach>.login-code>.img {
    background: url("../../assets/files/7d633be84261a709f4f307c4e7dab942.png") left top no-repeat;
    background-size: 255px 48px;
    position: absolute;
    left: 0;
    top: 0;
    width: 255px;
    height: 48px;
    z-index: 2
}

.com-dialog-login .dialog-login-box>.tab-content-w .tab-content-coach>.login-code>.code {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 200px;
    height: 200px;
    background: url("../../assets/files/3476971d4d13a3421c9619ec7ce84e5e.png") center center no-repeat;
    background-size: 174px 174px;
    border: 1px solid #eee
}

.com-login-pannel .input-box>form>.row select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url("../../assets/files/4047b1ec9715fd3a9818df3dde9a3cf1.png") right 10px center no-repeat #fff
}

.com-login-pannel .input-box>form>.row .submit-btn.loading {
    background: url("../../assets/files/b0438773813bf9a2eabe20147a3f29e2.gif") center no-repeat;
    background-size: contain;
    cursor: default;
    color: transparent
}

.com-dialog-login-share .dialog-login-box .other-login-type>.qq {
    width: 44px;
    height: 44px;
    background: url("../../assets/files/f4078891d88bdf4b07ebb580f72840c4.png") center center no-repeat;
    background-size: 44px 44px;
    display: block;
    margin: 20px auto 0
}

.com-login-share .loading {
    min-width: 300px;
    min-height: 180px;
    margin: 0 auto;
    background: url("../../assets/files/85f1c93260c1a7335a6e30ce1fe0f5b2.gif") center no-repeat;
    background-size: 100px
}

.com-footer-flex-panel {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 110px;
    z-index: 0
}

.com-footer-flex-panel>.content-box {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    z-index: 3
}

.com-footer-flex-panel>.content-box>.flex-close {
    position: absolute;
    top: 45px;
    right: 100px;
    height: 32px;
    width: 32px;
    line-height: 30px;
    border: none;
    background: url("../../assets/files/620b6e8cf3a4e36deba36f5e8f78fbe5.png") center no-repeat;
    font-size: 32px;
    color: #999;
    text-align: center;
    cursor: pointer
}

.com-footer-flex-panel>.content-box>.bg-img {
    position: absolute;
    top: 0;
    left: 90px;
    width: 160px;
    height: 125px;
    background: url("../../assets/files/11d274126fb4a3ce2876037345e1954d.png") center no-repeat;
    background-size: 100%
}

.baidu-search-box input {
    line-height: 24px;
    padding: 0 20px 0 5px;
    background: url("../../assets/files/9b0522d7a24f31d2702e7baa8b575e94.png") right 5px center no-repeat;
    border-color: #37B5F8
}

.com-appdown-dialog>.dialog-box>.dialog-content>.erwm {
    width: 296px;
    height: 296px;
    margin: 20px auto;
    background: url("../../assets/files/dcd41a46d528d96dc110be54407faf44.png") center no-repeat;
    background-size: 80% auto;
    border: 1px solid #e9e9e9;
    border-radius: 6px
}

.com-appdown-dialog>.dialog-box>.btn-dialog-close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    background: url("../../assets/files/d381506045f2d8582bdf00c0d5b09d45.jpg") center no-repeat;
    background-size: 40%;
    border: 1px solid #e9e9e9;
    border-radius: 50%;
    cursor: pointer
}

.com-dialog-first-visited2>.content-box>.content {
    height: 30px;
    line-height: 30px;
    padding-left: 25px;
    background: url("../../assets/files/c454c61f8db82676e5a7d815ac0458e5.png") left center no-repeat;
    background-size: 22px 15px;
    font-size: 18px
}

.com-dialog-first-visited3 .visited-content-box>.item-left>.row-city .city-name {
    padding-right: 20px;
    background: url("../../assets/files/ac7a65de254ed4b793fec8e915767497.png") right center no-repeat;
    color: #37B5F8;
    cursor: pointer;
    font-size: 14px
}

.com-dialog-first-visited3 .visited-content-box>.item-left>.row-baomin .select-baomin {
    width: 260px;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    margin-left: 0;
    padding: 0 10px;
    border: 1px solid #e9e9e9;
    outline: 0;
    -webkit-appearance: none;
    background: url("../../assets/files/b202869db721d36e059a395366ec278e.png") right 10px center no-repeat #fff
}

.com-dialog-first-visited4 .visited-content-box>.item-left>.row-city .city-name {
    padding-right: 20px;
    background: url("../../assets/files/0e4926aeb52a2955cd85b7faeed2aa33.png") right center no-repeat;
    color: #37B5F8;
    cursor: pointer;
    font-size: 14px
}

.com-dialog-first-visited4 .visited-content-box>.item-left>.row-baomin .select-baomin {
    width: 260px;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    margin-left: 0;
    padding: 0 10px;
    border: 1px solid #e9e9e9;
    outline: 0;
    -webkit-appearance: none;
    background: url("../../assets/files/026013145c714bb61581f2fd4df84a5d.png") right 10px center no-repeat #fff
}

body,
html {
    overflow-x: hidden
}

.container .none-data-block:before {
    display: block;
    content: '';
    width: 100%;
    height: 2.4rem;
    background: url("../../assets/files/12269a28a995a89f8407725fff30cb36.png") center top no-repeat;
    background-size: 2.8rem
}

.container .page-empty-data span:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background: url("../../assets/files/12269a28a995a89f8407725fff30cb36.png") center 30px no-repeat;
    background-size: 280px
}

.container .no-data {
    margin: 30px;
    background: url("../../assets/files/b12469ee38c879f3d0f59dc3392fe978.png") center top no-repeat;
    background-size: 50px 50px;
    padding-top: 60px;
    text-align: center;
    color: #999
}

.container .star-w-s {
    width: 90px;
    height: 20px;
    margin: 10px 0;
    background: url("../../assets/files/d59d67daf676ffce9e157bd3a0634dee.png") left center no-repeat
}

.container .star-w-s>.bfb {
    height: 100%;
    background: url("../../assets/files/07c954300da670874111fd227ae902fb.png") left center no-repeat
}

.container .star-w-m {
    width: 110px;
    height: 20px;
    margin: 10px 0;
    background: url("../../assets/files/e7754c64750c1cb6b742ea4b10dac05a.png") left center no-repeat
}

.container .star-w-m>.bfb {
    height: 100%;
    background: url("../../assets/files/7c543ae5cb114b37005a31933ba1af4a.png") left center no-repeat
}
</style>