
import { defineComponent, toRefs, Ref } from 'vue';
import {mapState} from 'vuex';

import {useForumList} from '@/compositions/bbs';
import ThreadList from '@/components/bbs/thread-list.vue';

export default defineComponent({

    components: {
        ThreadList
    },

    props: {
        page: Number
    },

    setup: (props) => {
        return useForumList();
    }

});
